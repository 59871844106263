@import "colors";

.modal-add-client .modal {
    width: 400px;
    margin: 50px auto;
}

.modal-add-loan .modal {
    width: 900px;
}

.modal-merge-tables .modal {
    width: calc(100vw - 48px);
    height: calc(100vh - 48px);
    margin: 24px;
}

.modal-add-financial-covenant .modal {
    width: 600px;
    margin: 50px auto;
}

.modal-instructions-gif .modal {
    width: 1100px;
}

.modal-instructions-gif .modal__header {
    margin-bottom:0px;
}

.modal-support .modal {
    width: 500px;
}

.modal-matcher-list .modal {
    width: 825px;
}

.modal-add-user .modal {
    width: 380px;
}

.modal-add-api-key .modal {
    width: 550px;
}

.modal-import-export-template .modal {
    width: 380px;
}

.modal-request-statements .modal {
    width: 450px;
}

.modal-request-statements .modal {
    width: 550px;
}

.modal-upload .modal {
    width: 400px;
}

.modal-confirmation .modal {
    width: 350px;
    margin: 22% auto;
}

.modal-confirmation-update .modal {
    width: 400px;
    margin: 22% auto;
}

.modal-pipeline-processing .modal {
    width: 550px;
}

.modal-interpolating .modal {
    width: 650px;
    margin: 22% auto;
}

.modal-download-document .modal {
    width: 400px;
}

.modal-doc-type-select .modal {
    overflow-y:auto;
    width: 440px;
}

.modal-doc-type-select .modal__header {
    overflow-y:auto;
    margin-bottom:0px;
}

.modal-doc-type-select .modal__footer {
    background-color: $gray-2;
    margin: 0px;
    padding: 0px;
}

.modal-loan-details {
    .modal {
        min-height: 70%;
        width: 800px;
    }
}

.modal-document-password-entry .modal {
    width: 450px;
    margin: 22% auto;
}


.doc-upload-modal {
    &.open {
        overflow-y: hidden !important;
    }

    .modal {
        width: 80%;
        max-width: 925px;
    }
}


.pdf-download-modal-class .modal {
    width: 50%;
    max-width: 700px;
    min-width: 450px;
}

.re-spread-warning-modal-class .modal {
    width: 25%;
    max-width: 600px;
    min-width: 350px;
    margin: 22% auto;
}

.change-log-modal-class .modal {
    width: 80%;
    max-width: 1200px;
    min-width: 450px;
}

.build-uca-equation-modal .modal {
    width: 60%;
}

.contact-support-modal .modal {
    width: 400px;
}

.comment-delete-modal .modal{
    width: 350px;
}

